import styles from './subscript-pay.module.css';
import { Table } from 'antd';

const addServiceColumn = [
  {
    title: <div style={{ paddingLeft: 10 }}>4대보험료 조회</div>,
    dataIndex: 'name',
    key: 'name',
    width: 480,
  },
  {
    title: (
      <div style={{ paddingLeft: 10 }} className={styles.column_title}>
        직원당 100
      </div>
    ),
    dataIndex: 'price',
    key: 'price',
  },
];

const addServiceData = [
  {
    key: '4',
    name: '건강보험 고지내역 가져오기',
    price: 'O',
  },
  {
    key: '5',
    name: '국민연금 결정내역 가져오기',
    price: 'O',
  },
  {
    key: '6',
    name: '고용보험 당월 보험료 가져오기',
    price: 'O',
  },
  {
    key: '7',
    name: '고용보험 실업급여 지원금 가져오기',
    price: 'O',
  },
];

export const SubscriptAddPay = () => {
  return (
    <Table
      className={styles.table}
      columns={addServiceColumn}
      dataSource={addServiceData}
      pagination={false}
    />
  );
};
