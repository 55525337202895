import styles from './subscript-estimated-amount.module.css';
import { Input, Switch } from 'antd';
import { addCommas, extractNumbers } from '../../utils';
import { useEffect, useState } from 'react';
import { Flex } from '../flex';
import { Button } from '../button';

export const SubscriptEstimatedAmount = ({ addButton }) => {
  const [addInsurance, setAddInsurance] = useState(false);
  const [employeeCount, setEmployeeCount] = useState('');
  const [monthPrice, setMonthPrice] = useState(0);

  useEffect(() => {
    let perPrice = 150;
    if (addInsurance) {
      perPrice += 100;
    }

    setMonthPrice(addCommas(perPrice * employeeCount));
  }, [addInsurance, employeeCount]);

  const onChangeAddInsurance = value => setAddInsurance(value);

  return (
    <div className={styles.price_check_container}>
      <Flex direction="column" rowGap={16}>
        <div className={styles.employee_count_wrap}>
          <span>급여대장에 포함된 사원 수</span>
          <div>
            <Input
              placeholder="입력"
              style={{ width: 100 }}
              controls={false}
              value={employeeCount}
              onChange={evt => setEmployeeCount(extractNumbers(evt))}
            />
            <span>명</span>
          </div>
        </div>
        <div className={styles.add_insurance_item_wrap}>
          <span>4대보험료 조회 추가</span>
          <Switch onChange={onChangeAddInsurance} />
        </div>
      </Flex>
      <div>
        <b>월 {monthPrice}원</b>
      </div>
      {addButton && (
        <Button href="/join" width={280}>
          무료로 시작하기
        </Button>
      )}
    </div>
  );
};
