import { NotificationCard } from './notification';
import { Flex } from './flex';
import { Button, IconButton } from './button';
import { useEffect, useState } from 'react';
import { DeleteIcon } from './icon';
import dayjs from 'dayjs';

const errorList = [
  {
    id: 'cenlabor07',
    url: 'https://docs.google.com/spreadsheets/d/1RJiTbehAuiRxAarvV1j20-X5207j06Tee0Su96T5hec/edit?usp=sharing',
  },
  {
    id: 'doyonomu',
    url: 'https://docs.google.com/spreadsheets/d/1yiZ4t0Kh4eXbs52ilABh7LzQTBm8WJXI-fHU_YN1YOo/edit?usp=sharing',
  },
  {
    id: 'shrm777',
    url: 'https://docs.google.com/spreadsheets/d/1PcViN-CcOkfUnv2EF13ZzDh39YQ9NE_pxfi5sEYZ_7c/edit?usp=sharing',
  },
  {
    id: 'noel',
    url: 'https://docs.google.com/spreadsheets/d/1DsrAHGhB03RTkqC1vn0nf7wwFIK5V5P5qBAB2muswr8/edit?usp=sharing',
  },
  {
    id: 'hrhelper',
    url: 'https://docs.google.com/spreadsheets/d/1DaOMpscwEMJ_WilNMSAlBeUucl-o9uG8WGe7p7Uczkg/edit?usp=sharing',
  },
  {
    id: 'publabor',
    url: 'https://docs.google.com/spreadsheets/d/1qQ5IiT5Lb8XV5N37UjcmzgHspKLcpUgMH2TX_CgXEZg/edit?usp=sharing',
  },
  {
    id: 'test_platform1',
    url: 'https://docs.google.com/spreadsheets/d/1qQ5IiT5Lb8XV5N37UjcmzgHspKLcpUgMH2TX_CgXEZg/edit?usp=sharing',
  },
];

export const ErrorNotiItem = ({ id }) => {
  const [errorItem, setErrorItem] = useState(undefined);
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    const getCloseCount = localStorage.getItem('errorNotiClose_2') || 0;

    if (getCloseCount >= 2 || dayjs() > dayjs('2024-12-09')) {
      setIsClose(true);
    }

    if (id) {
      const findItem = errorList.find(item => item.id === id);

      if (findItem) {
        setErrorItem(findItem);
      } else {
        setErrorItem(undefined);
      }
    } else {
      setIsClose(true);
    }
  }, []);

  const openErrorDocs = () => {
    const errorItem = errorList.find(item => item.id === id);

    if (errorItem) {
      window.open(errorItem.url);
    }
  };

  const closeErrorNoti = () => {
    const closeCount = localStorage.getItem('errorNotiClose_2') || 0;

    localStorage.setItem('errorNotiClose_2', (Number(closeCount) + 1).toString());

    setIsClose(true);
  };

  if (isClose || errorItem === undefined) {
    return '';
  }

  return (
    <NotificationCard
      type="error"
      style={{
        width: 650,
        marginBottom: 30,
      }}
      message={
        <Flex>
          <p style={{ lineHeight: '20px' }}>
            24년 8월 이후 생성된 급여대장에서 일부 사원의 경우 공제합계 총액이 다른 사례가
            발견되었어요.
            <br />
            내용 확인 후 정상적인 상태인지 확인 부탁드려요.
            <br /> 불편을 드려 죄송합니다. 해당 이슈가 재발하지 않도록 하곘습니다.
          </p>
          <Flex style={{ flex: '0 0 auto' }}>
            <Button type="line-gray" size="sm" onClick={() => openErrorDocs(id)}>
              급여대장 확인하기
            </Button>
            <IconButton style={{ border: 'none', background: 'none' }} onClick={closeErrorNoti}>
              <DeleteIcon />
            </IconButton>
          </Flex>
        </Flex>
      }
    />
  );
};
