import styles from './subscript-pay.module.css';
import { Table } from 'antd';
import { Flex } from '../flex';

const basicServiceColumn = [
  {
    title: <div style={{ paddingLeft: 10 }}>노무 관리</div>,
    dataIndex: 'name',
    key: 'name',
    width: 480,
  },
  {
    title: (
      <Flex style={{ paddingLeft: 10 }} rowGap={8} className={styles.column_title}>
        <em className={styles.point_red_text}>50%</em>
        <span className={styles.delete_text}>300원</span>
        사원당 150원
      </Flex>
    ),
    dataIndex: 'price',
    key: 'price',
    minWidth: 200,
  },
];

const basicServiceData = [
  {
    key: '0',
    name: '인사DB관리',
    price: 'O',
  },
  {
    key: '1',
    name: '근로관계 서식 출력',
    price: 'O',
  },
  {
    key: '21',
    name: '근로계약서 자동 작성',
    price: 'O',
  },
  {
    key: '12',
    name: '근태 관리',
    price: 'O',
  },
  {
    key: '13',
    name: '연차 휴가 관리',
    price: 'O',
  },
  {
    key: '14',
    name: '휴직 관리',
    price: 'O',
  },
  {
    key: '15',
    name: '퇴사 관리',
    price: 'O',
  },
  {
    key: '16',
    name: '급여 관리',
    price: 'O',
  },
  {
    key: '17',
    name: '수임업체 가져오기 (4대보험 EDI 연동)',
    price: 'O',
  },
];

export const SubscriptBasicPay = () => {
  return (
    <Table
      className={styles.table}
      columns={basicServiceColumn}
      dataSource={basicServiceData}
      pagination={false}
    />
  );
};
