import { Flex } from 'components/flex';
import styles from './Subscription.module.css';
import { Button } from 'components/button';

import { callApi, extractNumbers, snackBar } from 'utils';
import { SubscriptContainer } from '../../../components/subscript/subscript-container';
import { NotificationCard } from '../../../components/notification';
import cmsBannerImage from '../../../assets/images/CMS_banner.webp';
import { Input } from '../../../components/input';
import { useEffect, useState } from 'react';
import { CheckBox } from '../../../components/selections';
import { Chips } from '../../../components/selections/chips';
import { CheckIcon } from '../../../components/icon/icons/Check';

const CircleIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
        fill="#E2E5EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9083 15.6008C10.6639 15.3571 10.6633 14.9614 10.907 14.7169L13.4001 12.2164L10.9071 9.71641C10.6633 9.47199 10.6639 9.07627 10.9083 8.83253C11.1527 8.58879 11.5484 8.58933 11.7922 8.83375L14.7253 11.775C14.9685 12.0189 14.9686 12.4137 14.7253 12.6576L11.7922 15.5995C11.5485 15.8439 11.1528 15.8445 10.9083 15.6008Z"
        fill="#1A1818"
      />
    </svg>
  );
};

const SuccessIcon = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.1663 20.0002C37.1663 10.7954 29.7044 3.3335 20.4997 3.3335C11.2949 3.3335 3.83301 10.7954 3.83301 20.0002C3.83301 29.2049 11.2949 36.6668 20.4997 36.6668C29.7044 36.6668 37.1663 29.2049 37.1663 20.0002Z"
        fill="url(#paint0_linear_5353_11713)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5416 13.0994C29.2845 13.7055 29.3955 14.7991 28.7894 15.5421L18.7631 27.8319L12.4266 22.4106C11.698 21.7873 11.6127 20.6914 12.236 19.9628C12.8594 19.2342 13.9553 19.1489 14.6839 19.7722L18.3196 22.8828L26.0989 13.3471C26.705 12.6042 27.7986 12.4933 28.5416 13.0994Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5353_11713"
          x1="3.83301"
          y1="3.3335"
          x2="37.5204"
          y2="33.3893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3478D1" />
          <stop offset="1" stopColor="#A67BED" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const SubscriptionApplication = () => {
  const [cmsFormData, setCmsFormData] = useState({
    phoneNumber: null,
    emailId: null,
    emailAddress: null,
    extra_insurance: null,
    isAgree: false,
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationSuccess, setApplicationSuccess] = useState(false);
  const [extraProductList, setExtraProductList] = useState([]);

  /**
   *
   * @param {string} target
   * @param {string} value
   */
  const onChangeCmsFormData = ({ target, value }) => {
    setCmsFormData({
      ...cmsFormData,
      [target]: value,
    });
  };

  const applicationCMS = async () => {
    setIsLoading(true);
    const { phoneNumber, emailAddress, emailId, extra_insurance } = cmsFormData;

    const param = {
      opId: sessionStorage.getItem('userId'),
      phone: phoneNumber,
      cms_mail: `${emailId}@${emailAddress}`,
      extra_insurance: extra_insurance,
      signup_site_link: window.location.href,
    };

    const response = await callApi('api/platform/requestCMS', param);

    if (response.result) {
      snackBar('결제 신청을 완료했어요.');
      setApplicationSuccess(true);
    } else {
      snackBar('결제 신청에 실패했어요.');
    }

    setIsLoading(false);
  };

  const onClickAddSubscript = (event, hpId) => {
    onChangeCmsFormData({
      target: 'extra_insurance',
      value: event.target.checked ? hpId : '',
    });
  };

  const getExtraProductList = async () => {
    const param = {
      opId: sessionStorage.getItem('userId'),
    };
    const response = await callApi('api/platform/getExtraProductList', param);

    if (response.result) {
      setExtraProductList(response.list);
    } else {
      setExtraProductList([]);
    }
  };

  useEffect(() => {
    getExtraProductList();
  }, []);

  useEffect(() => {
    const { phoneNumber, emailAddress, emailId, isAgree } = cmsFormData;

    if (phoneNumber && emailAddress && emailId && isAgree) {
      if (phoneNumber.length === 11) {
        return setButtonDisabled(false);
      }
    }

    setButtonDisabled(true);
  }, [cmsFormData]);

  return (
    <Flex direction="column" alignItems="flex-start" rowGap={40} style={{ marginBottom: 120 }}>
      <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>결제/구독 신청</h2>
      <NotificationCard
        type={'info'}
        size={'small'}
        style={{ width: '100%' }}
        message={
          <p
            style={{
              color: 'var(--gray-700---text, #52545C)',
              lineHeight: '20px',
            }}
          >
            결제/구독 관련 도움이 필요하시다면 help@hulampro.com 또는 채널톡으로 문의하세요.
          </p>
        }
      />
      <div style={{ width: '100%' }}>
        {applicationSuccess ? (
          <div className={styles.cms_success_application_container}>
            <div className={styles.cms_success_application_title}>
              <SuccessIcon />
              <strong>CMS 결제 신청이 접수되었어요.</strong>
            </div>
            <p>
              신청하신 연락처로 <strong>CMS 신청 간편 제공 동의서가 발송</strong>될 예정이에요.
              <br />
              (공휴일 또는 휴일인 경우 1일 이상 소요될 수 있음)
              <br />
              CMS 신청 간편 제공 동의서 서명 후 CMS 결제 신청 완료까지+2일이 소요될 수 있어요.
            </p>
          </div>
        ) : (
          <div className={styles.cms_application_container}>
            <img src={cmsBannerImage} width={342} alt="CMS 신청 양식"></img>
            <div className={styles.cms_application_form_container}>
              <div className={styles.cms_application_form}>
                <div className={styles.cms_application_form_item}>
                  <span>휴대폰 번호</span>
                  <Input
                    value={cmsFormData.phoneNumber}
                    onChange={e => {
                      const number = extractNumbers(e);

                      onChangeCmsFormData({ target: 'phoneNumber', value: number });
                    }}
                    placeholder={'-제외하고 숫자만 입력하세요.'}
                  />
                </div>
                <div className={styles.cms_application_form_item}>
                  <span>이메일</span>
                  <Flex>
                    <Input
                      value={cmsFormData.emailId}
                      onChange={e =>
                        onChangeCmsFormData({ target: 'emailId', value: e.target.value })
                      }
                      placeholder={'hulampro'}
                    />
                    <span>@</span>
                    <Input
                      value={cmsFormData.emailAddress}
                      onChange={e => {
                        onChangeCmsFormData({ target: 'emailAddress', value: e.target.value });
                      }}
                      placeholder={'hulampro.com'}
                    />
                  </Flex>
                </div>
                <div className={styles.cms_application_form_item}>
                  <span>추가서비스 구독</span>
                  {extraProductList.map(item => (
                    <Chips
                      key={`extra_product_${item.id}`}
                      id={`product_${item.id}`}
                      label={
                        <Flex gap={4}>
                          <CheckIcon size={16} color={'#8B9099'} /> {item.product_name}
                        </Flex>
                      }
                      checked={cmsFormData.extra_insurance !== ''}
                      onClick={event => onClickAddSubscript(event, item.id)}
                    ></Chips>
                  ))}
                </div>
              </div>
              <div className={styles.is_agree_container}>
                <label className={styles.is_agree_checkbox}>
                  <CheckBox
                    value={cmsFormData.isAgree}
                    onChange={e =>
                      onChangeCmsFormData({ target: 'isAgree', value: e.target.checked })
                    }
                  />
                  (필수) 정기과금 이용약관
                </label>
                <a
                  href={
                    'https://shimmer-vein-8d5.notion.site/1345807a34bf80be978bf4547da7ba3f?pvs=4'
                  }
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <CircleIcon />
                </a>
              </div>
              <Button
                size={'sm'}
                style={{ width: '100%' }}
                isDisabled={buttonDisabled || isLoading}
                onClick={applicationCMS}
              >
                결제 신청
              </Button>
            </div>
          </div>
        )}
        <SubscriptContainer notiHide />
      </div>
    </Flex>
  );
};
