import createIcon from '../createIcon';

export const ClockDefaultIcon = props => {
  return createIcon({
    size: props.size,
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.54286C7.88153 4.54286 4.54286 7.88153 4.54286 12C4.54286 16.1185 7.88153 19.4571 12 19.4571C16.1185 19.4571 19.4571 16.1185 19.4571 12C19.4571 7.88153 16.1185 4.54286 12 4.54286ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 6.29143C12.426 6.29143 12.7714 6.63681 12.7714 7.06286V11.5232L15.6364 12.9557C16.0175 13.1463 16.1719 13.6096 15.9814 13.9907C15.7909 14.3718 15.3275 14.5262 14.9464 14.3357L11.655 12.69C11.3937 12.5593 11.2286 12.2922 11.2286 12V7.06286C11.2286 6.63681 11.574 6.29143 12 6.29143Z"
        fill="#1A1818"
      />
    ),
  });
};
