import { Flex } from 'components/flex';
import styles from './SubscriptionFeePage.module.css';

import { NotificationCard } from 'components/notification';
import { SubscriptContainer } from '../../components/subscript/subscript-container';

export const SubscriptionFeePage = () => {
  return (
    <div className={styles.container}>
      <div className={'section'}>
        <Flex
          direction="column"
          alignItems="flex-start"
          rowGap={40}
          style={{ marginBottom: 120, display: 'none' }}
        >
          <h4 className={styles.title}>비용 안내</h4>
          <NotificationCard
            type="info"
            size="small"
            message={'현재는 무료 사용이 가능해요. 자유롭게 테스트해 보세요.'}
          />
          <Flex
            style={{
              width: '100%',
              height: 280,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              flexShrink: 0,
              borderRadius: '6px',
              background: 'var(--gray-50-background-divider, #F3F4F8)',
              fontSize: '16px',
            }}
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M51.3334 28.0001C51.3334 15.1134 40.8867 4.66675 28.0001 4.66675C15.1134 4.66675 4.66675 15.1134 4.66675 28.0001C4.66675 40.8867 15.1134 51.3334 28.0001 51.3334C40.8867 51.3334 51.3334 40.8867 51.3334 28.0001Z"
                fill="#E2E5EA"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.0001 16.8195C29.3424 16.8195 30.4306 17.9077 30.4306 19.2501V28.0001C30.4306 29.3424 29.3424 30.4306 28.0001 30.4306C26.6577 30.4306 25.5695 29.3424 25.5695 28.0001V19.2501C25.5695 17.9077 26.6577 16.8195 28.0001 16.8195ZM25.5695 36.7501C25.5695 35.4077 26.6577 34.3195 28.0001 34.3195H28.022C29.3643 34.3195 30.4525 35.4077 30.4525 36.7501C30.4525 38.0924 29.3643 39.1806 28.022 39.1806H28.0001C26.6577 39.1806 25.5695 38.0924 25.5695 36.7501Z"
                fill="#ACB1BA"
              />
            </svg>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16, textAlign: 'center' }}>
              <span>이용요금은 11월 공개됩니다.</span>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                기본 정책
                <ul style={{ display: 'flex', gap: 4, flexDirection: 'column', fontSize: 14 }}>
                  <li>&middot; 무료체험 1개월</li>
                  <li>&middot; 체험 종료 후 1개월의 사용 후, 말일 청구</li>
                </ul>
              </div>
            </div>
          </Flex>
        </Flex>
        <SubscriptContainer />
      </div>
    </div>
  );
};
