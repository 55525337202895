import styles from './chips.module.css';

export const Chips = ({ id, label, checked, onClick }) => {
  if (!id || !label) {
    return '';
  }

  return (
    <div className={styles.chips}>
      <input id={id} type="checkbox" checked={checked} onChange={onClick} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
