import { Modal } from 'antd';
import { Flex } from '../../../../components/flex';
import styles from './exception-list-modal.module.css';
import { SearchBox } from '../../../../components/input';
import React, { useEffect, useState } from 'react';
import { ChipsContainer } from '../../../../components/selections/chips-container';
import { Button, MultiButton } from '../../../../components/button';
import Scrollbars from 'react-custom-scrollbars-2';
import { callApi } from '../../../../utils';

export const ExceptionListModal = ({
  modalInfo = { open: false, title: '' },
  modalClose,
  setException,
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [exceptionList, setExceptionList] = useState([]);
  const [openSearchList, setOpenSearchList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const onDeleteItem = id => {
    const filterData = exceptionList.filter(item => item.mb_c_no !== id);
    setExceptionList(filterData);
  };

  const onReset = () => {
    setExceptionList([]);
  };

  const setExceptionItem = targetData => {
    const findItem = exceptionList.find(item => item.mb_c_no === targetData.mb_c_no);

    if (findItem) {
      const filterData = exceptionList.filter(item => item.mb_c_no !== targetData.mb_c_no);
      setExceptionList(filterData);
    } else {
      setExceptionList([
        ...exceptionList,
        { mb_c_no: targetData.mb_c_no, mb_name: targetData.mb_name },
      ]);
    }

    setOpenSearchList(false);
  };

  const getCompanyList = async () => {
    let opId = sessionStorage.getItem('userId');

    const param = {
      opId: opId,
      insurance_type: type,
    };

    const { result, data } = await callApi('api/platform/billing/getHulamproCompanyList', param);

    if (result) {
      return setCompanyList(data);
    } else {
      return [];
    }
  };

  const getExclusionList = async () => {
    let opId = sessionStorage.getItem('userId');

    const param = {
      opId: opId,
      insurance_type: type,
    };

    const { result, data } = await callApi('api/platform/billing/getExclusionInsuranceList', param);

    if (result) {
      return setExceptionList(data);
    } else {
      return [];
    }
  };

  const onFocusSearchList = () => {
    if (searchText === '') {
      setSearchList(companyList);
    }
    setOpenSearchList(true);
  };

  useEffect(() => {
    if (modalInfo.open) {
      let newType = '';

      switch (modalInfo.title) {
        case '건강보험':
          newType = '건강';
          break;
        case '국민연금':
          newType = '국민';
          break;
        case '고용보험':
          newType = '고용';
          break;
        default:
          newType = '';
      }

      setType(newType);

      setSaveLoading(false);
      if (type !== newType) {
        setExceptionList([]);
      }
    } else {
      setType('');
      setExceptionList([]);
    }
  }, [modalInfo]);

  useEffect(() => {
    if (type) {
      Promise.allSettled([getCompanyList(), getExclusionList()]).then(() => {
        setIsLoading(false);
      });
    }
  }, [type]);

  useEffect(() => {
    if (searchText) {
      const filterData = companyList.filter(item => item.mb_name.includes(searchText));

      setSearchList(filterData);
      if (filterData.length > 0) {
        setOpenSearchList(true);
      } else {
        setOpenSearchList(false);
      }
    } else {
      setOpenSearchList(false);
    }
  }, [searchText]);

  return (
    <Modal
      title={`${modalInfo.title} 예외 설정`}
      open={modalInfo.open}
      closeIcon={false}
      width={630}
      styles={{
        body: {
          height: 400,
        },
      }}
      footer={
        <MultiButton>
          <Button type="line-gray" size={'sm'} onClick={modalClose} width={60}>
            닫기
          </Button>
          <Button
            type="primary"
            size={'sm'}
            width={120}
            onClick={() => {
              setSaveLoading(true);
              setException(exceptionList, type);
            }}
            isDisabled={saveLoading}
          >
            예외 설정하기
          </Button>
        </MultiButton>
      }
    >
      <Flex direction="column" alignItems={'flex-start'} rowGap={40}>
        <div className={styles.exception_company_list_search_container}>
          <span className={styles.exception_company_list_search_title}>수임업체 목록</span>
          <div className={styles.exception_search_box}>
            <SearchBox
              style={{ width: '100%' }}
              placeholder={'입력'}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onFocus={onFocusSearchList}
              onBlur={() => {
                setTimeout(() => {
                  setOpenSearchList(false);
                }, 250);
              }}
              disabled={isLoading}
            />
            <Scrollbars
              className={styles.search_exception_company_list_container}
              autoHeight
              autoHeightMax={150}
              style={{
                visibility: `${openSearchList ? 'visible' : 'hidden'}`,
                position: 'absolute',
                top: '44px',
              }}
            >
              {searchList
                .filter(item => !exceptionList.some(exItem => exItem.mb_c_no === item.mb_c_no))
                .map(item => (
                  <div
                    key={`search_box_${item.mb_c_no}`}
                    className={`${styles.search_item}`}
                    onClick={() => setExceptionItem(item)}
                  >
                    {item.mb_name}
                  </div>
                ))}
            </Scrollbars>
          </div>
        </div>
        <Flex direction={'column'} style={{ width: '100%' }}>
          <div className={styles.exception_company_list_container}>
            <span className={styles.exception_company_list_title}>예외 설정된 수임업체</span>
            {exceptionList.length > 0 && (
              <span className={styles.exception_company_list_count}>{exceptionList.length}개</span>
            )}
          </div>
          {exceptionList.length > 0 && (
            <ChipsContainer
              list={exceptionList}
              height={120}
              onDeleteItem={onDeleteItem}
              onReset={onReset}
              id={'mb_c_no'}
              name={'mb_name'}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
