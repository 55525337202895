import { Collapse } from 'antd';
import styles from './subscript-faq-item.module.css';
import imageFAQ1 from '../../assets/images/faq_1.webp';
import imageFAQ2 from '../../assets/images/faq_2.webp';
import imageFAQ3 from '../../assets/images/faq_3.webp';

const FAQ1 = () => {
  return (
    <div>
      <p className={styles.answer}>
        A. ‘이용권 신청' 후 이용권이 부여되면 결제일에 후불 결제 청구됩니다.
      </p>
      <div className={styles.fee_container}>
        <img
          width={725}
          src={imageFAQ1}
          alt="이용권 신청 후 이용권이 부여되면 결제일에 후불 결제 청구됩니다."
        />
      </div>
    </div>
  );
};

const FAQ2 = () => {
  return (
    <div>
      <div>
        <p className={styles.answer}>
          A. 가입일로부터 1개월까지 무료로 사용 후 +1개월이 지난 시점부터 청구됩니다.
        </p>
        <div className={styles.example_text}>
          <span>
            예시1) 가입일 4월 3일이면 5월 2일까지 무료 사용, 결제일(월 말일)인 6월 30일 첫 결제
          </span>
          <span>예시2) 가입일 4월 30일이면 5월 29일까지 무료 사용, 결제일인 6월 30일 첫 결제</span>
        </div>
      </div>
      <div className={styles.fee_container}>
        <img
          width={933}
          src={imageFAQ2}
          alt="전월 귀속연월 급여대장의 사원수 + 재직중 사원수 (근로계약서 확정 상태)"
        />
      </div>
    </div>
  );
};

const FAQ3 = () => {
  return (
    <div>
      <p className={styles.answer}>A.</p>
      <div className={styles.fee_container}>
        <img width={1260} src={imageFAQ3} alt="과금 대상 직원은 어떻게 집계되나요?" />
      </div>
    </div>
  );
};

const FAQ4 = () => {
  return (
    <div>
      <p className={styles.answer}>
        A. ① 회원 가입 ②로그인 후에 이용권 메뉴 > 이용권 신청에서 진행 가능합니다.
      </p>
    </div>
  );
};

const items = [
  {
    key: '1',
    label: 'Q. 이용요금은 어떻게 청구되나요?',
    children: <FAQ1 />,
  },
  {
    key: '2',
    label: 'Q. 이용요금은 언제  청구되나요?',
    children: <FAQ2 />,
  },
  {
    key: '3',
    label: 'Q. 과금 대상 직원은 어떻게 집계되나요?',
    children: <FAQ3 />,
  },
  {
    key: '4',
    label: 'Q. 구독 신청은 어떻게 하나요?',
    children: <FAQ4 />,
  },
];

export const SubscriptFaqItem = () => {
  return <Collapse items={items} className={styles.collapse_custom} expandIconPosition="end" />;
};
