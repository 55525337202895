import { Flex } from '../flex';
import styles from './subscript-item.module.css';
import { Tag } from 'antd';
// import { Button } from '../button';
// import { PlusIcon, RollBackIcon } from '../icon';
// import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const HashIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 16C0 4 4 0 16 0C28 0 32 4 32 16C32 28 28 32 16 32C4 32 0 28 0 16Z"
        fill="#8B9099"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6905 9.00876C15.0371 9.06654 15.2713 9.39441 15.2136 9.74108L14.7478 12.5355H19.1141L19.6147 9.53185C19.6725 9.18517 20.0003 8.95098 20.347 9.00876C20.6937 9.06654 20.9279 9.39441 20.8701 9.74108L20.4044 12.5355H22.3636C22.7151 12.5355 23 12.8204 23 13.1718C23 13.5233 22.7151 13.8082 22.3636 13.8082H20.1923L19.4616 18.192H21.6566C22.008 18.192 22.2929 18.4769 22.2929 18.8284C22.2929 19.1798 22.008 19.4647 21.6566 19.4647H19.2495L18.7489 22.4684C18.6911 22.815 18.3632 23.0492 18.0166 22.9914C17.6699 22.9337 17.4357 22.6058 17.4935 22.2591L17.9592 19.4647H13.5929L13.0923 22.4684C13.0346 22.815 12.7067 23.0492 12.36 22.9914C12.0133 22.9337 11.7792 22.6058 11.8369 22.2591L12.3027 19.4647H9.63636C9.28491 19.4647 9 19.1798 9 18.8284C9 18.4769 9.28491 18.192 9.63636 18.192H12.5148L13.2454 13.8082H10.3434C9.99198 13.8082 9.70707 13.5233 9.70707 13.1718C9.70707 12.8204 9.99198 12.5355 10.3434 12.5355H13.4575L13.9581 9.53185C14.0159 9.18517 14.3438 8.95098 14.6905 9.00876ZM14.5357 13.8082L13.8051 18.192H18.1713L18.902 13.8082H14.5357Z"
        fill="white"
      />
    </svg>
  );
};

const BasicIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 16C0 4 4 0 16 0C28 0 32 4 32 16C32 28 28 32 16 32C4 32 0 28 0 16Z"
        fill="#936FFA"
      />
      <path
        d="M14.6641 10.5312V20.9492H13.7383V10.5312H14.6641ZM6.17969 18.2422C8.99805 16.9062 10.4395 14.9082 10.6445 12.418H6.66016V11.668H11.582C11.5703 14.6621 10.1758 17.3164 6.68359 18.9922L6.17969 18.2422ZM18.6016 10.9531V12.2773H23.7578V10.9531H24.6719V15.1133H21.625V16.2266H25.9141V16.9883H16.4219V16.2266H20.7109V15.1133H17.6875V10.9531H18.6016ZM17.6523 20.7266V17.8203H18.5781V19.9531H24.8594V20.7266H17.6523ZM18.6016 14.375H23.7578V13.0039H18.6016V14.375Z"
        fill="white"
      />
    </svg>
  );
};

export const SubscriptItem = ({
  is_extra_type,
  product_name,
  description,
  is_free_type,
  // start_dt = '-',
  // end_dt = '-',
  unScribe,
  base_price,
  unit_type,
}) => {
  const [unitType, setUnitType] = useState('사원당');

  useEffect(() => {
    if (unit_type) {
      if (unit_type === 'employee') {
        setUnitType('사원당');
      }
    }
  }, []);

  if (unScribe) {
    return (
      <Flex columnGap={16}>
        {is_extra_type === '0' ? <BasicIcon /> : <HashIcon />}
        <div className={styles.item_title}>
          <h4>
            {product_name}
            {is_extra_type === '0' ? (
              <Tag color="success" className="height-16">
                필수 구독
              </Tag>
            ) : (
              <Tag className="height-16">추가 서비스</Tag>
            )}
          </h4>
          <span>{description}</span>
        </div>
        <span className={styles.date_span}>
          {base_price}원/{unitType}
        </span>
        {/*<Button type="line-gray" size="sm">*/}
        {/*  <PlusIcon size={20} />*/}
        {/*  구독하기*/}
        {/*</Button>*/}
      </Flex>
    );
  } else {
    return (
      <Flex columnGap={16}>
        {is_extra_type === '0' ? <BasicIcon /> : <HashIcon />}
        <div className={styles.item_title}>
          <h4>{product_name}</h4>
          <span>{description}</span>
        </div>
        {/*{is_free_type === '1' && (*/}
        {/*  <span className={styles.date_span}>*/}
        {/*    {dayjs(start_dt).format('YY-MM-DD')}~{dayjs(end_dt).format('YY-MM-DD')}*/}
        {/*  </span>*/}
        {/*)}*/}
        {is_free_type === '0' ? <Tag color="green">구독</Tag> : <Tag color="blue">무료 체험</Tag>}
        {/*{is_free_type === '0' && !unScribe ? (*/}
        {/*  <Button type="line-gray" size="sm">*/}
        {/*    <RollBackIcon size={20} />*/}
        {/*    구독취소*/}
        {/*  </Button>*/}
        {/*) : (*/}
        {/*  <Button type="line-gray" size="sm">*/}
        {/*    <PlusIcon size={20} />*/}
        {/*    구독하기*/}
        {/*  </Button>*/}
        {/*)}*/}
      </Flex>
    );
  }
};
