import { Link } from 'react-router-dom';
import styles from './Button.module.css';

export const Button = props => {
  const {
    ref,
    isDisabled = false,
    children,
    type = 'primary',
    size = 'md',
    width,
    className,
    style,
    href,
    htmlType = 'button',
    ...rest
  } = props;

  const contentProps = { children };

  const basicStyle = {
    width: width ? width : 'auto',
    ...style,
  };

  return (
    <>
      {href ? (
        <Link
          ref={ref}
          to={href}
          className={[`${styles.hulam_button} ${styles[type]} ${styles[size]} ${className}`]}
          style={basicStyle}
          {...rest}
          disabled={isDisabled}
        >
          <ButtonContent {...contentProps} />
        </Link>
      ) : (
        <button
          ref={ref}
          type={htmlType}
          className={[`${styles.hulam_button} ${styles[type]} ${styles[size]} ${className}`]}
          style={basicStyle}
          {...rest}
          disabled={isDisabled}
        >
          <ButtonContent {...contentProps} />
        </button>
      )}
    </>
  );
};

function ButtonContent(props) {
  const { children } = props;
  return <>{children}</>;
}
