import createIcon from '../createIcon';

export const CheckIcon = props => {
  const color = props.color ? props.color : '#1A1818';
  return createIcon({
    size: props.size,
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6321 5.71804C20.06 6.0561 20.1239 6.66608 19.7748 7.08046L10.1537 18.5L4.34992 13.6918C3.93027 13.3442 3.88113 12.7329 4.24016 12.3266C4.5992 11.9202 5.23045 11.8726 5.65011 12.2203L9.89825 15.7396L18.2251 5.85623C18.5742 5.44185 19.2042 5.37998 19.6321 5.71804Z"
        fill={color}
      />
    ),
  });
};
